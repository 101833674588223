<template>
    <b-container class="dashboad bg-light page-wrapper">
        <b-row class="main-content-wrapper" no-gutters>
            <b-col cols="12">
                <h1>PDF</h1>
            </b-col>
            <b-col cols="12">
                <b-button variant="primary" @click.prevent="$refs.html2Pdf.generatePdf()">Download PDF</b-button>
            </b-col>
            <b-col cols="12">
                <vue-html2pdf
                    :show-layout="false"
                    :float-layout="true"
                    :enable-download="false"
                    :preview-modal="true"
                    :filename="`transaction`"
                    :pdf-quality="2"
                    pdf-format="a4"
                    pdf-orientation="portrait"
                    pdf-content-width="800px"
                    :manual-pagination="true"
                    ref="html2Pdf"
                >
                    <section slot="pdf-content">
                        <section class="pdf-item pdfHeader text-center py-2 ml-0">
                            <img :src="require('@/assets/img/spirecta-logo.png')" class="logoImg"/>
                        </section>
                        <section class="pdf-item text-right mt-2 pr-4 dateRow">
                            <span>Date: {{ $moment(currentDate).format('DD-MM-YYYY') }}</span>
                        </section>
                        <section class="pdf-item text-center mt-2 pl-3 pr-4 headingRow">
                            <h2>{{ $t( translationPath + 'table_heading') }}</h2>
                        </section>
                        <section class="pdf-item text-left mt-2 pl-3 pr-4 descriptionRow">
                            <i18n :path="translationPath + 'table_heading_description'" tag="p"></i18n>
                        </section>
                        <section class="pdf-item text-left mt-3 pl-3 pr-4 tableRow">
                            <b-table
                                class="spirecta-simple-table income-exepense-table-overviews mt-3" show-empty
                                hover
                                responsive
                                striped
                                :items="items"
                                :fields="fieldValues"
                                stacked="md"
                                :emptyText="$t(translationPath + 'table.no_data')"
                                ref="IncomeOverview"
                            >
                                <!-- Date -->
                                <template v-slot:cell(date)="row">
                                    <span class="date font-weight-bold">{{ row.item.date | formatDate(currentCOA.locale, 'YYYY-MM-DD') }}</span>
                                </template>

                                <!-- Transaction Title -->
                                <template v-slot:cell(title)="row">
                                    {{ row.item.title }}
                                </template>

                                <!-- Amount Title -->
                                <template v-slot:cell(amount)="row">
                                    <span :class='"font-weight-bold transaction_" + row.item.transaction_subtype'>{{(row.value) | formatAmount(currentCOA.locale, currentCOA.currency, currentCOA.currency_iso,true,0)}}</span>
                                </template>

                                <template v-slot:head(account_title)>
                                    <span class="btns_switch_description">
                                        <span>
                                            {{ $t(currentUser.strict_accounting_mode ? 'common.account' : 'common.category') }}
                                        </span>
                                        /
                                        <span>
                                            {{ $t('common.description') }}
                                        </span>
                                        /
                                        <span>
                                            {{ $t(translationPath + 'import_id') }}
                                        </span>
                                    </span>
                                </template>

                                <!-- Account Title -->
                                <template v-slot:cell(account_title)="row">
                                    <template v-if="descriptionMode === DESCR_MODES.CR_DR">
                                        <i18n :path="'common.transaction_subtypes.'+row.item.transaction_subtype" tag="span" class="text-gray" style="font-size:90%">
                                        <template v-slot:cr_account v-if="Object.prototype.hasOwnProperty.call(row.item, 'cr_entries') && ( 0 in row.item.cr_entries)">
                                            <b-link :to="'/reports/performance/accounts/'+row.item.cr_entries[0].account_id+'/view'" class="text-gray">{{ row.item.cr_entries[0].account_title }}</b-link>
                                        </template>
                                        <template v-slot:dr_account v-if="Object.prototype.hasOwnProperty.call(row.item, 'dr_entries') && ( 0 in row.item.dr_entries)">
                                            <b-link :to="'/reports/performance/accounts/'+row.item.dr_entries[0].account_id+'/view'" class="text-gray">{{ row.item.dr_entries[0].account_title }}</b-link>
                                        </template>
                                        </i18n>
                                    </template>
                                    <template v-else-if="descriptionMode === DESCR_MODES.DESCRIPTION">
                                        {{ row.item.transaction_description }}
                                    </template>
                                    <template v-else-if="descriptionMode === DESCR_MODES.IMPORT_ID">
                                        <b-link :href="'/transactions/view/?import_id=' + row.item.import_id">{{ row.item.import_title }}</b-link>
                                    </template>
                                </template>
                            </b-table>
                        </section>
                    </section>
                </vue-html2pdf>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import formatDate from '@/assets/filters/formatDate'
import formatAmount from '@/assets/filters/formatAmount'
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  name: 'PdfSandboxView',
  filters: {
    formatDate,
    formatAmount
  },
  components: {
    VueHtml2pdf
  },
  data () {
    return {
      currentDate: new Date(),
      htmlOption: {
        margin: 0
      },
      translationPath: 'transactions.view.',
      items: [],
      descriptionMode: null
    }
  },
  async created () {
    const descrModes = ['CR_DR', 'DESCRIPTION', 'IMPORT_ID']
    this.DESCR_MODES = {}
    descrModes.map((item, index) => {
      this.DESCR_MODES[item] = index + 1
    })
    this.DESCR_MODES_LENGTH = descrModes.length
    this.descriptionMode = this.DESCR_MODES.CR_DR
    await this.loadData()
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    fieldValues () {
      return [
        { key: 'date', label: this.$t(this.translationPath + 'table.head.date'), sortable: false, class: 'td-date' },
        { key: 'title', label: this.$t(this.translationPath + 'table.head.title'), sortable: false, class: 'td-title' },
        { key: 'amount', label: this.$t(this.translationPath + 'table.head.amount'), sortable: false, class: 'text-left text-md-right td-amount' },
        { key: 'account_title', label: this.$t((this.currentUser.strict_accounting_mode ? 'common.account' : 'common.category')), sortable: false, class: 'td-account-title' }
      ]
    }
  },
  methods: {
    async loadData () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/transactions?sort[0][key]=date&sort[0][direction]=desc&limit=100&page=1`)
        const assets = response.data.data
        this.items = assets.map(item => {
          const amount = (item.amount).toString().replace('-', '')
          return Object.assign({}, item, { amount: Number(amount) })
        }).filter((item, el) => el <= 4)
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
